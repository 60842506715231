import React from 'react'
import { useTranslation } from 'react-i18next'

import CardProfile from './card-profile'

export default function ProfileList() {
  const { t } = useTranslation()

  return (
    <>
<div className="w-full max-w-6xl mx-auto px-4 sm:px-0 lg:px-0 justify-center justify-center mt-[30px] mb-[100px]">
  <div className='w-full flex justify-center flex-wrap'>
        <CardProfile
          image={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG_oM2JRKjnKfsJTW04ud6AziSfy-FS_2hK-q0o8LOdw&s`}
          name={`Silvia Triebl`}
          title={`Founder & CEO`}
          company={`Benjamiin 4 Kids GmbH`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/silviatriebl/?originalSubdomain=ch'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/v2/C5603AQEpIEOi6QZHtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516359532728?e=1740009600&v=beta&t=FOZGsqph7nIMgb2EwaANOBepFWA8INj4_CtMkyAgzzc`}
          name={`RENé EICHENBERGER`}
          title={`Investor`}
          company={`New Venture Associates`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/ren%C3%A9-eichenberger-4394318/'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/v2/D4E03AQHxl-1RdCfkjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691063849461?e=1740009600&v=beta&t=c3_9QP6_PwEktXqSgYulWu7BqSuWctYopBCu8qZq4lM`}
          name={`Abouzar Rahmani`}
          title={`Investor`}
          company={`FoodYoung Labs & Studio`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/abouzar-rahmani-b3b890139'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/v2/D4E03AQHi1Wm-kAjZPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701692642388?e=1740009600&v=beta&t=YD3jeSWVr-SOOgvS7FJZBBDetISdk7aLXQfrVs_O_gQ`}
          name={`Guillaume Thomassin`}
          title={`Investor`}
          company={`MarTech FC`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/guillaume-thomassin/'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />

      <CardProfile
          image={`https://media.licdn.com/dms/image/v2/D4E03AQECYZSg4NNKkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712491403322?e=1740009600&v=beta&t=8AFnsOmqBOdhJiZ2iHtUkJXSoC6uGI_5ivT1Eo9SHW8`}
          name={`BERNHARD BAUHOFER`}
          title={`Investor`}
          company={`Sparring Partners`}
          icon1={t('iconLinkedInBlack')} 
          linkIcon1={'https://www.linkedin.com/in/bernhard-bauhofer-59416113/'}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />
        <CardProfile
          image={`https://profile-images.xing.com/images/de5104851693e31bec914d94ba8ee2f3-13/antoine-f-goetschel.256x256.jpg`}
          name={`Antoine F. Goetschel`}
          title={`Investor`}
          company={`Dr.iur. Rechtsanwalt`}
          //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
          //cta={`Read more`}
          //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
        />


      </div>
</div>
    </>
  )
}
